.GmHomeImage {
  width: 100%;
  height: 100%;

  overflow: hidden;
}

.zappyride_logo {
  position: absolute;
  z-index: 100;
  right: 5%;
  height: 10vh;
}

.gm-hero-image {
  max-width: 100%;
  max-height: 100%;
}

#GmHomeImage .zappyride_logo {
  position: absolute;
  z-index: 100;
  right: 5%;
  height: 10vh;
}
#GmHomeImage p {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  height: 22px;
  font-size: 16px;
  margin: 30px;
  font-family: 'Overpass', sans-serif;
}

#GmHomeImage p #logo {
  padding-left: 5px;
  height: 35px;
}

.LoginContainer {
  width: 740px;
  height: auto;
  z-index: 200;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding: 24px;
  overflow: auto;
  max-height: 80%;

  @media (max-width: 768px) {
    width: 90%;
  }
}

.LoginContainer > .textContainer {
  display: flex;
  flex-direction: row;
  gap: 24px;

  margin: 12px 0;
  height: auto;

  padding: 0 8px;
}

.textContainerWizard {
  width: 100%;

  .row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
}

.LoginContainer > .textContainer > p {
  margin: 0 0 12px !important;
  height: auto !important;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #777777;
}

.LoginContainer > .inputContainer {
  margin: 0 0 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding: 0 8px;
}

.LoginContainer > .inputContainer > input {
  margin: 0 8px 0 0;
}

#LoginButton {
  display: flex;
  align-items: center;
  line-height: 50px;
  justify-content: center;
  height: 40px;
  width: auto;
  font-size: 14px;
  background-color: #007dbe;
  color: white;
  text-decoration: none;
  z-index: inherit;
  margin: auto auto 16px;
  padding: 10px 15px;
}

#LoginButton:disabled {
  background-color: lightgray;
}
.GMLogo {
  z-index: 100;
  height: 60px;
  margin: 0;
}

.languageSelectorCotnainer {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media only screen and (max-width: 375px) {
  .GmHomeImage {
    height: 100%;
  }
  .LoginContainer {
    width: 80vw;
    height: 60vh;
    z-index: 300;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.logoTextContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  .description {
    font-family: Overpass;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #777777;
    margin: 16px 0 !important;
    width: 100% !important;
  }

  .title {
    font-family: Overpass;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
  }
}

.description.middle-row {
  width: 100% !important;
  margin: 25px 0 30px !important;
  color: #777777;
  text-align: center;
}

@media print {
  #incentives-tab-list {
    display: none;
  }

  #tabPanels [hidden] {
    display: block !important;
  }

  #tabPanels #tabs--panel--0 {
    margin-bottom: 30px;
  }
}
